<script>
import Layout from "../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Team",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Team",
      items: [
        {
          text: "Team",
        },
        {
          text: "Team",
          active: true,
        },
      ],
      form: {
        allEmployee: null,
        checked: [],
      },
     allEmployeeOptions: [
        { text: "All Employee", value: null },
        "John Stewart",
        "Parker",
        "Williamson",
      ],
    };
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="row align-items-center">
      <b-tabs content-class="" justified class="nav-tabs-custom">
          <b-tab active>
            <template v-slot:title>
             
              <span class="d-none d-sm-block">Requests</span>
            </template>
            <!-- *********************************  My Account Start  *********************************** -->

            <div>
             me 2

              <!-- end .border-->
            </div>

            <!-- *********************************  My Account End    *********************************** -->
          </b-tab>
             <b-tab >
            <template v-slot:title>
             
              <span class="d-none d-sm-block">Approved</span>
            </template>
            <!-- *********************************  My Account Start  *********************************** -->

            <div>
             me 2

              <!-- end .border-->
            </div>

            <!-- *********************************  My Account End    *********************************** -->
          </b-tab>
       

          <b-tab>
            <template v-slot:title>
              
              <span class="d-none d-sm-block">Shift Release</span>
            </template>
            <div>
               <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card dashboard__wrapper">
          <div class="">
            <div class="table-responsive">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <th class="f-14 roboto">Sr. #</th>
                    <th class="f-14 roboto">Employee Name</th>
                    <th class="f-14 roboto">User ID</th>
                    <th class="f-14 roboto">User Email</th>
                    <th class="f-14 roboto">Contact</th>
                    <th class="f-14 roboto">Start Date</th>
                    <th class="f-14 roboto">End Date</th>
                    <th class="f-14 roboto">Reasons</th>
                    <th class="f-14 roboto">Requests</th>
             
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" class="f-14 roboto align-middle">
                      {{ index + 1 }}
                    </th>
                    <td class="f-14 roboto align-middle">John Stewart</td>
                    <td class="f-14 roboto align-middle">
                     CFA67890
                    </td>
              
                    <td class="f-14 roboto align-middle">Parker@chickfila.com</td>
                    <td class="f-14 roboto align-middle">+1 6587 3912</td>
                    <td class="f-14 roboto align-middle">
                     06 Feb 2022
05:00pm
                    </td>
                    <td class="f-14 roboto align-middle">
                    06 Feb 2022
05:00pm
                    </td>
                    <td class="f-14 roboto align-middle">
                       Cold/Flu 

                    </td>
               
                    <td class="f-14 roboto align-middle">
                    
                    
                      <div
                       
                        class="action-buttons d-flex gap-2 justify-content-between"
                      >
                        <button
                          class="btn-outline-success btn py-1"
                         
                          v-b-tooltip.hover
                          title="Accept"
                          v-b-modal.modal-reason

                        >
                          <i class="fas fa-check-circle"></i>
                        </button>
                        <button
                         
                          class="btn-outline-danger btn py-1"
                         
                          v-b-tooltip.hover
                          title="Reject"
                          v-b-modal.modal-reason
                        >
                          <i class="fas fa-times-circle"></i>
                        </button>
                      </div>
                   
                    </td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

              <!-- end .border-->
            </div>
          </b-tab>
        </b-tabs>
    </div>
    
  
  
  </Layout>
</template>
